// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-start-project-tsx": () => import("./../../../src/pages/start-project.tsx" /* webpackChunkName: "component---src-pages-start-project-tsx" */),
  "component---src-pages-works-tsx": () => import("./../../../src/pages/works.tsx" /* webpackChunkName: "component---src-pages-works-tsx" */),
  "component---src-templates-blog-page-index-tsx": () => import("./../../../src/templates/BlogPage/index.tsx" /* webpackChunkName: "component---src-templates-blog-page-index-tsx" */),
  "component---src-templates-pages-index-tsx": () => import("./../../../src/templates/Pages/index.tsx" /* webpackChunkName: "component---src-templates-pages-index-tsx" */),
  "component---src-templates-work-page-index-tsx": () => import("./../../../src/templates/WorkPage/index.tsx" /* webpackChunkName: "component---src-templates-work-page-index-tsx" */)
}

